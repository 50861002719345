import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"

import dayjs, { Dayjs } from "dayjs"
import InvoiceOrder from "../models/InvoiceOrder.model."

export type PaginationType = {
  page: number
  pageSize: number
}

type FilterType = {
  dateSelector: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  customerName: string
  email: string
  mobile: string
  isGstResolved: string
  businessEntity: string
  bookingId: string
  bookingCode: string
}

type InvoicesState = {
  invoices: InvoiceOrder[]
  loading: boolean
  pageInfo: {
    totalRowCount: number
  }
  pagination: PaginationType
  filters: FilterType
}

const initialFilterState = {
  dateSelector: "month",
  startDate: dayjs().startOf("month"),
  endDate: dayjs(),
  customerName: "",
  email: "",
  mobile: "",
  isGstResolved: "none",
  businessEntity: "all",
  bookingId: "",
  bookingCode: "",
}

const initialPaginationState = {
  page: 0,
  pageSize: 10,
}

export const invoicesInitialState: InvoicesState = {
  invoices: [],
  loading: false,
  pageInfo: {
    totalRowCount: 0,
  },
  pagination: initialPaginationState,
  filters: initialFilterState,
}

export type fetchInvoicesPayloadType = {
  payload: {
    page: number
    pageSize: number
    startDate: string
    endDate: string
    customerName?: string
    email?: string
    mobile?: string
    isGstResolved?: string
    businessEntity?: string
    bookingId?: string
    bookingCode?: string
  }
}

export const invoicesSlice = createSlice({
  name: "login",
  initialState: invoicesInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchInvoices: (state, action: fetchInvoicesPayloadType) => {
      state.loading = true
    },
    fetchInvoicesSuccess: (
      state,
      {
        payload,
      }: { payload: { invoices: InvoiceOrder[]; totalRowCount: number } },
    ) => {
      state.pageInfo.totalRowCount = payload.totalRowCount
      state.invoices = payload.invoices
      state.loading = false
    },
    fetchInvoicesFailed: (state) => {
      state.loading = false
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    resetFilters: (state) => {
      state.filters = initialFilterState
      state.pagination = initialPaginationState
    },
  },
})

export const {
  fetchInvoices,
  fetchInvoicesSuccess,
  fetchInvoicesFailed,
  setPagination,
  setFilters,
  resetFilters,
} = invoicesSlice.actions

export const selectInvoices = (state: RootState) => state.invoices

export default invoicesSlice.reducer
