import React from "react"
import { FormikProps } from "formik"
import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
import { RootState } from "../../app/store"
import {
  DrawerDataRow,
  ReconciliationFormContainer,
} from "../Transactions/styles"
import { ReconcilePrimaryButton } from "../Transactions/StyledButton"
import { ReconciliationFormValues } from "../../models/reconciliation.model"
import { Transaction } from "../../models/transactions.model"
import { CustomerSelector } from "./CustomerSelector"
import { TransactionDetails } from "./TransactionDetails"
import { PendingInvoicesList } from "./PendingInvoicesList"

type ReconciliationFormProps = {
  formikProps: FormikProps<ReconciliationFormValues>
  transactionData: Transaction
}

const ReconciliationForm: React.FC<ReconciliationFormProps> = ({
  formikProps,
  transactionData,
}) => {
  const { loading } = useSelector((state: RootState) => state.reconciliation)
  return (
    <ReconciliationFormContainer>
      <CustomerSelector transactionSource={transactionData.invoiceSource} />
      <TransactionDetails
        amount={formikProps.values.balanceAmount}
        totalAmount={transactionData.amount}
        description={transactionData.description}
        error={formikProps.errors.balanceAmount}
      />
      <PendingInvoicesList
        transactionData={transactionData}
        formikProps={formikProps}
      />
      <DrawerDataRow>
        <ReconcilePrimaryButton
          variant="contained"
          onClick={formikProps.handleSubmit as any}
          disabled={
            loading ||
            !formikProps.isValid ||
            !formikProps.dirty ||
            (formikProps.values.reconciliationItems.length === 0 &&
              !formikProps.values.addExcessPayment) ||
            formikProps.values.excessAmount === 0
          }
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </ReconcilePrimaryButton>
      </DrawerDataRow>
    </ReconciliationFormContainer>
  )
}

export default ReconciliationForm
