import { Stack, useMediaQuery } from "@mui/material"
import PageHeader from "../../components/PageHeader/PageHeader"
import InvoiceTable from "./InvoiceTable"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  fetchInvoices,
  PaginationType,
  resetFilters,
  selectInvoices,
  setFilters,
  setPagination,
} from "../../slices/invoicesSlice"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import queryString from "query-string"
import dayjs from "dayjs"
import InvoiceFilters from "./InvoiceFilters"

const Invoices = () => {
  const { pageInfo, pagination, filters } = useAppSelector(selectInvoices)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [rowCountState, setRowCountState] = useState(
    pageInfo?.totalRowCount || 0,
  )

  const isMiniScreen = useMediaQuery("(max-width: 400px)")
  const isSmallScreen = useMediaQuery(
    "(min-width: 400px) and (max-width: 576px)",
  ) // For screens up to 600px wide
  const isMediumScreen = useMediaQuery(
    "(min-width: 576px) and (max-width: 1028px)",
  )

  const constructApiRequestParams = () => {
    return {
      ...filters,
      ...pagination,
      startDate: dayjs(filters.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate).format("YYYY-MM-DD"),
    }
  }

  useEffect(() => {
    // URL Parameters to Redux State
    const params = queryString.parse(location.search)
    if (Object.keys(params).length !== 0) {
      const updatedFilters = { ...filters, ...params }
      // URL has filter parameters
      if (!Array.isArray(params.page) && !Array.isArray(params.pageSize)) {
        const page = parseInt(params.page || "0")
        const pageSize = parseInt(params.pageSize || "10")
        dispatch(setPagination({ page, pageSize }))
      }
      dispatch(setFilters(updatedFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredFilterState = Object.fromEntries(
      // eslint-disable-next-line
      Object.entries(filters).filter(([_, value]) => value !== ""),
    )

    // set filters to URL for sharing purpose
    const params = queryString.stringify({
      ...filteredFilterState,
      ...pagination,
    })
    navigate({ search: params })
    dispatch(fetchInvoices(constructApiRequestParams()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, pagination])

  // reset filter handler

  const resetFilterHandler = () => {
    dispatch(resetFilters())
  }

  //handle refresh
  const handleRefresh = () => {
    dispatch(fetchInvoices(constructApiRequestParams()))
  }

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState,
    )
  }, [pageInfo?.totalRowCount, setRowCountState])

  const setPaginationModel = (page: PaginationType) => {
    dispatch(setPagination(page))
  }

  return (
    <>
      <PageHeader title="Invoices" />
      <Stack width="97%" margin="auto" gap={1} mt={1} pb={6}>
        <InvoiceFilters
          handleRefresh={handleRefresh}
          resetFilterHandler={resetFilterHandler}
          isSmallScreen={isSmallScreen}
          isMediumScreen={isMediumScreen}
          isMiniScreen={isMiniScreen}
        />
        <InvoiceTable
          rowCount={rowCountState}
          paginationModel={pagination}
          onPaginationModelChange={setPaginationModel}
          isSmallScreen={isSmallScreen}
        />
      </Stack>
    </>
  )
}

export default Invoices
