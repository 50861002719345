import React from "react"
import {
  FormControl,
  Tooltip,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  SelectChangeEvent,
  Card,
  TextField,
} from "@mui/material"
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Dayjs } from "dayjs"
import { selectInvoices, setFilters } from "../../slices/invoicesSlice"
import DateRangeSelector from "../../components/DateRangeSelector/DateRangeSelector"
import { BusinessEntity } from "../../constants/data"

interface InvoiceFilterPropss {
  handleRefresh: () => void
  resetFilterHandler: () => void
  isSmallScreen: boolean
  isMediumScreen: boolean
  isMiniScreen?: boolean
}

const InvoiceFilters: React.FC<InvoiceFilterPropss> = ({
  handleRefresh,
  resetFilterHandler,
  isSmallScreen,
  isMediumScreen,
  isMiniScreen,
}) => {
  const dispatch = useAppDispatch()
  const { filters } = useAppSelector(selectInvoices)
  const [isEmailValid, setIsEmailValid] = React.useState(true)
  const [isMobileValid, setIsMobileValid] = React.useState(true)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === "email") {
      const isValid = /^\S+@\S+\.\S+$/.test(value)
      setIsEmailValid(isValid)
      setTimeout(() => {
        setIsEmailValid(true)
      }, 3000)
    }

    if (name === "mobile") {
      const isValid = /^\d{10}$/.test(value)
      setIsMobileValid(isValid)
      setTimeout(() => {
        setIsMobileValid(true)
      }, 3000)
    }

    dispatch(setFilters({ ...filters, [name]: value }))
  }

  const handleDateRangeSelection = (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => {
    dispatch(
      setFilters({
        ...filters,
        ...newState,
      }),
    )
  }

  return (
    <Card
      elevation={2}
      sx={{
        padding: 2,
        marginBottom: "10px",
        backgroundColor: "#FFFFFF",
        display: "grid",
        columnGap: 2,
        rowGap: 2,
        gridTemplateColumns:
          isSmallScreen || isMiniScreen
            ? "1fr"
            : isMediumScreen
            ? "repeat(3, 9fr)"
            : "repeat(4, 9fr)",
      }}
    >
      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <TextField
          label="Customer Name"
          name="customerName"
          size="small"
          value={filters.customerName}
          onChange={handleInputChange}
        />
      </FormControl>

      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <TextField
          label="Email"
          name="email"
          size="small"
          value={filters.email}
          onChange={handleInputChange}
          error={!isEmailValid}
          helperText={!isEmailValid ? "Invalid email format" : ""}
        />
      </FormControl>

      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <TextField
          label="Mobile"
          name="mobile"
          size="small"
          value={filters.mobile}
          onChange={handleInputChange}
          error={!isMobileValid}
          helperText={!isMobileValid ? "Mobile should be 10 digits" : ""}
        />
      </FormControl>

      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <InputLabel id="business-entity-label">Business Entity</InputLabel>
        <Select
          labelId="business-entity-label"
          id="business-entity"
          value={filters.businessEntity}
          label="Business Entity"
          onChange={(event: SelectChangeEvent) => {
            dispatch(
              setFilters({
                ...filters,
                businessEntity: event.target.value,
              }),
            )
          }}
        >
          <MenuItem value="all">All</MenuItem>
          {Object.values(BusinessEntity).map((entity) => (
            <MenuItem key={entity} value={entity}>
              {entity.charAt(0).toUpperCase() + entity.slice(1).toLowerCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <TextField
          label="Booking ID"
          name="bookingId"
          size="small"
          value={filters.bookingId}
          onChange={handleInputChange}
        />
      </FormControl>

      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <TextField
          label="Booking Code"
          name="bookingCode"
          size="small"
          value={filters.bookingCode}
          onChange={handleInputChange}
        />
      </FormControl>

      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <InputLabel id="gst-resolved">GST Resolved</InputLabel>
        <Select
          labelId="select-gst-resolved"
          id="gst-resolved"
          label="GST Resolved"
          value={filters.isGstResolved || "none"}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                isGstResolved: e.target.value as string,
              }),
            )
          }
        >
          <MenuItem value={"none"}>None</MenuItem>
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
        </Select>
      </FormControl>

      <DateRangeSelector
        dateSelector={filters.dateSelector}
        filterState={filters}
        updateFilterState={handleDateRangeSelection}
      />

      <Stack
        direction="row"
        gap={2}
        marginTop={"10px"}
        alignItems="center"
        justifyContent={isSmallScreen || isMiniScreen ? "center" : "flex-end"}
        style={{
          gridColumn:
            isSmallScreen || isMiniScreen ? 1 : isMediumScreen ? "3" : "4",
        }}
      >
        <Tooltip title="Refresh">
          <Button
            variant="outlined"
            startIcon={<RefreshRoundedIcon />}
            onClick={handleRefresh}
            style={{ overflow: "hidden" }}
          >
            Refresh
          </Button>
        </Tooltip>
        <Tooltip title="Reset Filter" arrow>
          <Button
            variant="outlined"
            startIcon={<CachedOutlinedIcon />}
            onClick={resetFilterHandler}
            style={{
              overflow: "hidden",
            }}
          >
            Reset
          </Button>
        </Tooltip>
      </Stack>
    </Card>
  )
}

export default InvoiceFilters
