import {
  CloseIconButton,
  DrawerContent,
  DrawerData,
  DrawerDataHeading,
  DrawerDataRow,
  DrawerHeader,
  DrawerTitle,
  ExternalLink,
  StyledDrawer,
} from "./styles"
import { Transaction } from "../../models/transactions.model"
import CloseIcon from "@mui/icons-material/Close"
import dayjs from "dayjs"
import { useState } from "react"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import {
  TransactionType,
  allowedOnlyBankAccountsRoles,
} from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { useAppSelector } from "../../app/hooks"
import { ReconcilePrimaryButton } from "./StyledButton"
import { formatPriceWithRupeeSymbol } from "../../utils/price-formatter"
import {
  getTransactionDateDisplayValue,
  getTransactionTypeDisplayValue,
  shouldShowReconcileButton,
} from "../../utils/transactionHelpers"
import { ExternalLinkIcon } from "./ExternalLinkIcon"
import ReconciliationDrawer from "../ReconciliaionDrawer"

type TransactionDetailProps = {
  open: boolean
  onClose: () => void
  transactionData: Transaction
}

const TransactionDetail = ({
  open,
  onClose,
  transactionData,
}: TransactionDetailProps) => {
  const [reconcileDialogOpen, setReconcileDialogOpen] = useState(false)
  const { role } = useAppSelector(selectLogin)

  const handleReconcileDialogOpen = () => {
    setReconcileDialogOpen(true)
  }

  const handleReconcileDialogClose = () => {
    setReconcileDialogOpen(false)
  }

  return (
    <>
      <StyledDrawer open={open} onClose={onClose} anchor="right">
        <DrawerHeader>
          <DrawerTitle>Transaction Details</DrawerTitle>
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        </DrawerHeader>
        <DrawerContent>
          <DrawerDataRow>
            <DrawerDataHeading>ID:</DrawerDataHeading>
            <DrawerData>{transactionData.id}</DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Date:</DrawerDataHeading>
            <DrawerData>
              {transactionData.date
                ? getTransactionDateDisplayValue(transactionData.date)
                : "--"}
            </DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Description:</DrawerDataHeading>
            <DrawerData>{transactionData.description || "--"}</DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Amount:</DrawerDataHeading>
            <DrawerData>
              {formatPriceWithRupeeSymbol(transactionData.amount)}
            </DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Type:</DrawerDataHeading>
            <DrawerData>
              {getTransactionTypeDisplayValue(
                transactionData.type as TransactionType,
              )}
            </DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Reference No:</DrawerDataHeading>
            <DrawerData>{transactionData.referenceNo}</DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Value Date:</DrawerDataHeading>
            <DrawerData>
              {transactionData.valueDate
                ? dayjs
                    .utc(transactionData.valueDate)
                    .utcOffset("+05:30")
                    .format("DD/MM/YYYY")
                : "--"}
            </DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Branch:</DrawerDataHeading>
            <DrawerData>{transactionData.branch || "--"}</DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Balance:</DrawerDataHeading>
            <DrawerData>
              {transactionData.balance
                ? formatPriceWithRupeeSymbol(transactionData.balance)
                : "--"}
            </DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Marked:</DrawerDataHeading>
            <DrawerData>{transactionData.isMarked ? "Yes" : "No"}</DrawerData>
          </DrawerDataRow>
          {allowedOnlyBankAccountsRoles.includes(role) &&
          transactionData?.settlements?.invoices?.length ? (
            <DrawerDataRow>
              <DrawerDataHeading>Invoice Number:</DrawerDataHeading>
              <DrawerData>
                {transactionData?.settlements?.invoices?.map(
                  (invoice: {
                    invoice_url: string
                    invoice_number: string
                  }) => {
                    return (
                      <ExternalLink
                        onClick={() => {
                          window.open(invoice.invoice_url)
                        }}
                      >
                        {invoice.invoice_number}
                        <ExternalLinkIcon />
                      </ExternalLink>
                    )
                  },
                )}
              </DrawerData>
            </DrawerDataRow>
          ) : null}
          {allowedOnlyBankAccountsRoles.includes(role) &&
          transactionData?.settlements?.payments?.length ? (
            <DrawerDataRow>
              <DrawerDataHeading>Payment Id:</DrawerDataHeading>
              <DrawerData>
                {transactionData?.settlements?.payments?.map(
                  (payment: {
                    payment_url: string
                    payment_number: string
                  }) => {
                    return (
                      <ExternalLink
                        onClick={() => {
                          window.open(payment.payment_number)
                        }}
                      >
                        {payment.payment_number}
                        <ExternalLinkIcon />
                      </ExternalLink>
                    )
                  },
                )}
              </DrawerData>
            </DrawerDataRow>
          ) : null}
          <DrawerDataRow>
            <DrawerDataHeading>Payee:</DrawerDataHeading>
            <DrawerData>{transactionData.payee}</DrawerData>
          </DrawerDataRow>
          <DrawerDataRow>
            <DrawerDataHeading>Bank Name:</DrawerDataHeading>
            <DrawerData>{transactionData.accountName}</DrawerData>
          </DrawerDataRow>
        </DrawerContent>
        {shouldShowReconcileButton(transactionData) ? (
          <DrawerDataRow>
            <ReconcilePrimaryButton
              variant="contained"
              onClick={() => handleReconcileDialogOpen()}
            >
              Reconcile
              <ArrowForwardIcon fontSize="small" sx={{ height: "14px" }} />
            </ReconcilePrimaryButton>
          </DrawerDataRow>
        ) : null}
      </StyledDrawer>
      {reconcileDialogOpen && (
        <ReconciliationDrawer
          open={reconcileDialogOpen}
          onClose={handleReconcileDialogClose}
          transactionData={transactionData}
        />
      )}
    </>
  )
}

export default TransactionDetail
