import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Divider from "../Divider/Divider"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { logout, selectLogin } from "../../slices/loginSlice"
import BhiveLogo from "../../assets/Bhive.png"
import { Wrapper, Logo, CustomListItem, MenuButtonWrapper } from "./styles"
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import GroupsIcon from "@mui/icons-material/Groups"
import {
  BOOKING_HISTORY,
  CENTERS,
  MEETING_ROOMS,
  USERS,
  AUDIT_LOGS,
  TRANSACTIONS,
  BANK_ACCOUNTS,
  INVOICES,
} from "../../constants/path"
import AssignmentIcon from "@mui/icons-material/Assignment"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import ReceiptIcon from "@mui/icons-material/Receipt"
import {
  allowedOnlyTrasactionsRoles,
  UserRole,
  allowedOnlyBankAccountsRoles,
} from "../../constants/data"
import BhiveIcon from "../../assets/BhiveIcon.png"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import { resetFilters } from "../../slices/transactionsSlice"
import { resetFilters as resetBankPageFilters } from "../../slices/bankAccountsSlice"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"

const sidemenuItems = [
  {
    title: "Booking History",
    path: BOOKING_HISTORY,
    icon: AssignmentIcon,
  },
  {
    title: "Centers",
    path: CENTERS,
    icon: LocationCityIcon,
  },
  {
    title: "Meeting Rooms",
    path: MEETING_ROOMS,
    icon: TableRestaurantIcon,
  },
  {
    title: "Users",
    path: USERS,
    icon: GroupsIcon,
  },
  {
    title: "Audit Logs",
    path: AUDIT_LOGS,
    icon: FormatListBulletedIcon,
  },
  {
    title: "Invoices",
    path: INVOICES,
    icon: ReceiptLongIcon,
  },
]

type SideMenuItemType = {
  title: string
  path: string
  icon: React.ComponentType
}

type SideMenuItemProps = {
  item: SideMenuItemType
  location: ReturnType<typeof useLocation>
  collapsed: boolean
}

const SideMenuItem: React.FC<SideMenuItemProps> = ({
  item,
  location,
  collapsed,
}) => (
  <Link to={item.path} key={item.path} style={{ textDecoration: "none" }}>
    <CustomListItem
      className={`${location.pathname === item.path && "active"}`}
    >
      {<item.icon />}
      {!collapsed && item.title}
    </CustomListItem>
  </Link>
)

const Sidemenu = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { name, role } = useAppSelector(selectLogin)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const logoutHandler = () => {
    dispatch(resetFilters())
    dispatch(resetBankPageFilters())
    dispatch(logout())
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} display="flex" flexDirection="column">
      <Logo className="logo" src={BhiveLogo} alt="logo" />
      <Divider />
      <Box flex="1" padding="12px 0px">
        {!allowedOnlyTrasactionsRoles.includes(role) && (
          <>
            {sidemenuItems.map((item) => (
              <SideMenuItem
                item={item}
                location={location}
                key={item.path}
                collapsed={false}
              />
            ))}
          </>
        )}
        {role !== UserRole.AUDITOR && (
          <Link to={TRANSACTIONS} style={{ textDecoration: "none" }}>
            <CustomListItem
              className={`${location.pathname === TRANSACTIONS && "active"}`}
            >
              <ReceiptIcon />
              Transactions
            </CustomListItem>
          </Link>
        )}
        {allowedOnlyBankAccountsRoles.includes(role) && (
          <Link to={BANK_ACCOUNTS} style={{ textDecoration: "none" }}>
            <CustomListItem
              className={`${location.pathname === BANK_ACCOUNTS && "active"}`}
            >
              <AccountBalanceIcon />
              Bank Accounts
            </CustomListItem>
          </Link>
        )}
        <CustomListItem onClick={() => dispatch(logout())}>
          {<ExitToAppIcon />}
          Logout
        </CustomListItem>
      </Box>
    </Box>
  )

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <>
      <MenuButtonWrapper>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </MenuButtonWrapper>

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
          },
        }}
      >
        {drawer}
      </Drawer>

      <Wrapper className={collapsed ? "collapsed" : ""}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Logo
            src={collapsed ? BhiveIcon : BhiveLogo}
            alt="logo"
            onClick={toggleCollapse}
          />
        </Box>
        {/* <Divider /> */}
        <Box flex="1" padding="12px 0px">
          {!allowedOnlyTrasactionsRoles.includes(role) && (
            <>
              {sidemenuItems.map((item) => (
                <SideMenuItem
                  item={item}
                  location={location}
                  key={item.path}
                  collapsed={collapsed}
                />
              ))}
            </>
          )}
          {role !== UserRole.AUDITOR && (
            <Link to={TRANSACTIONS} style={{ textDecoration: "none" }}>
              <CustomListItem
                className={`${location.pathname === TRANSACTIONS && "active"}`}
              >
                <ReceiptIcon />
                {!collapsed && "Transactions"}
              </CustomListItem>
            </Link>
          )}
          {allowedOnlyBankAccountsRoles.includes(role) && (
            <Link to={BANK_ACCOUNTS} style={{ textDecoration: "none" }}>
              <CustomListItem
                className={`${location.pathname === BANK_ACCOUNTS && "active"}`}
              >
                <AccountBalanceIcon />
                {!collapsed && "Bank Accounts"}
              </CustomListItem>
            </Link>
          )}
          <CustomListItem onClick={logoutHandler}>
            <ExitToAppIcon />
            {!collapsed && "Logout"}
          </CustomListItem>
        </Box>

        <Stack
          padding="8px 0px"
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          bgcolor="#fdb915"
          color="white"
        >
          <AccountCircleIcon />
          {!collapsed && <Typography variant="subtitle1">{name}</Typography>}
        </Stack>
      </Wrapper>
    </>
  )
}

export default Sidemenu
