interface LineItemResponse {
  name: string
  rate: number
  unit: string
  item_id: string
  discount: number
  quantity: number
  tax_name: string
  tax_type: string
  hsn_or_sac: string
  item_total: number
  description: string
  product_type: string
  tax_percentage: number
}

class LineItem {
  name = ""
  rate = 0
  unit = ""
  itemId = ""
  discount = 0
  quantity = 0
  taxName = ""
  taxType = ""
  hsnOrSac = ""
  itemTotal = 0
  description = ""
  productType = ""
  taxPercentage = 0

  constructor(lineItem: LineItemResponse) {
    if (lineItem) {
      this.name = lineItem.name
      this.rate = lineItem.rate
      this.unit = lineItem.unit
      this.itemId = lineItem.item_id
      this.discount = lineItem.discount
      this.quantity = lineItem.quantity
      this.taxName = lineItem.tax_name
      this.taxType = lineItem.tax_type
      this.hsnOrSac = lineItem.hsn_or_sac
      this.itemTotal = lineItem.item_total
      this.description = lineItem.description
      this.productType = lineItem.product_type
      this.taxPercentage = lineItem.tax_percentage
    }
  }
}

interface InvoiceResponse {
  date: string
  gst_no: string
  discount: number
  due_date: string
  invoice_id: string
  line_items: LineItemResponse[]
  customer_id: string
  invoice_url: string
  discount_type: string
  gst_treatment: string
  payment_terms: string
  invoice_number: string
  place_of_supply: string
  is_discount_before_tax: boolean
}

class Invoice {
  date = ""
  gstNo = ""
  discount = 0
  dueDate = ""
  invoiceId = ""
  lineItems: LineItem[] = []
  customerId = ""
  invoiceUrl = ""
  discountType = ""
  gstTreatment = ""
  paymentTerms = ""
  invoiceNumber = ""
  placeOfSupply = ""
  isDiscountBeforeTax = false

  constructor(invoice: InvoiceResponse) {
    if (invoice) {
      this.date = invoice.date
      this.gstNo = invoice.gst_no
      this.discount = invoice.discount
      this.dueDate = invoice.due_date
      this.invoiceId = invoice.invoice_id
      this.lineItems = invoice.line_items.map((item) => new LineItem(item))
      this.customerId = invoice.customer_id
      this.invoiceUrl = invoice.invoice_url
      this.discountType = invoice.discount_type
      this.gstTreatment = invoice.gst_treatment
      this.paymentTerms = invoice.payment_terms
      this.invoiceNumber = invoice.invoice_number
      this.placeOfSupply = invoice.place_of_supply
      this.isDiscountBeforeTax = invoice.is_discount_before_tax
    }
  }
}

interface CustomFieldsResponse {
  center_code: string
  center_name: string
  no_of_seats: number
  international_payments: boolean
  escrow_account_payments: boolean
}

class CustomFields {
  centerCode = ""
  centerName = ""
  noOfSeats = 0
  internationalPayments = false
  escrowAccountPayments = false

  constructor(customFields: CustomFieldsResponse) {
    if (customFields) {
      this.centerCode = customFields.center_code
      this.centerName = customFields.center_name
      this.noOfSeats = customFields.no_of_seats
      this.internationalPayments = customFields.international_payments
      this.escrowAccountPayments = customFields.escrow_account_payments
    }
  }
}

interface ContactPersonResponse {
  email: string
  last_name: string
  first_name: string
  is_primary_contact: boolean
  phone: string
  mobile: string
}

class ContactPerson {
  email = ""
  lastName = ""
  firstName = ""
  isPrimaryContact = false
  phone = ""
  mobile = ""

  constructor(contactPerson: ContactPersonResponse) {
    if (contactPerson) {
      this.email = contactPerson.email
      this.lastName = contactPerson.last_name
      this.firstName = contactPerson.first_name
      this.isPrimaryContact = contactPerson.is_primary_contact
      this.phone = contactPerson.phone
      this.mobile = contactPerson.mobile
    }
  }
}

interface ContactInfoResponse {
  gst_no: string
  contact_id: string
  legal_name: string
  company_name: string
  contact_name: string
  currency_code: string
  custom_fields: CustomFieldsResponse
  gst_treatment: string
  language_code: string
  contact_persons: ContactPersonResponse[]
  place_of_contact: string
}

class ContactInfo {
  gstNo = ""
  contactId = ""
  legalName = ""
  companyName = ""
  contactName = ""
  currencyCode = ""
  customFields = {}
  gstTreatment = ""
  languageCode = ""
  contactPersons: ContactPerson[] = []
  placeOfContact = ""

  constructor(contactInfo: ContactInfoResponse) {
    if (contactInfo) {
      this.gstNo = contactInfo.gst_no
      this.contactId = contactInfo.contact_id
      this.legalName = contactInfo.legal_name
      this.companyName = contactInfo.company_name
      this.contactName = contactInfo.contact_name
      this.currencyCode = contactInfo.currency_code
      this.customFields = new CustomFields(contactInfo.custom_fields)
      this.gstTreatment = contactInfo.gst_treatment
      this.languageCode = contactInfo.language_code
      this.contactPersons = contactInfo.contact_persons.map(
        (person) => new ContactPerson(person),
      )
      this.placeOfContact = contactInfo.place_of_contact
    }
  }
}

interface GstContactResponse {
  user_id: string
  contact_id: string
  contact_info: ContactInfoResponse
  is_active: boolean
  business_entity: string
  gst_details_id: string
  id: string
  created_at: string
  created_timestamp: number
  updated_at: string | null
}

class GstContact {
  userId = ""
  contactId = ""
  contactInfo = {} as ContactInfo
  isActive = false
  businessEntity = ""
  gstDetailsId = ""
  id = ""
  createdAt = ""
  createdTimestamp = 0
  updatedAt: string | null = null

  constructor(gstContact: GstContactResponse) {
    if (gstContact) {
      this.userId = gstContact.user_id
      this.contactId = gstContact.contact_id
      this.contactInfo = new ContactInfo(gstContact.contact_info)
      this.isActive = gstContact.is_active
      this.businessEntity = gstContact.business_entity
      this.gstDetailsId = gstContact.gst_details_id
      this.id = gstContact.id
      this.createdAt = gstContact.created_at
      this.createdTimestamp = gstContact.created_timestamp
      this.updatedAt = gstContact.updated_at
    }
  }
}

interface OrderNotesResponse {
  user_id: string
  center_id: string
  pass_count: number
  booking_date: string
}

class OrderNotes {
  userId = ""
  centerId = ""
  passCount = 0
  bookingDate = ""

  constructor(orderNotes: OrderNotesResponse) {
    if (orderNotes) {
      this.userId = orderNotes.user_id
      this.centerId = orderNotes.center_id
      this.passCount = orderNotes.pass_count
      this.bookingDate = orderNotes.booking_date
    }
  }
}

interface OrderDetailsResponse {
  id: string
  notes: OrderNotesResponse
  amount: number
  entity: string
  status: string
  receipt: string
  attempts: number
  currency: string
  offer_id: string | null
  payments: any[]
  amount_due: number
  created_at: number
  amount_paid: number
}

class OrderDetails {
  id = ""
  notes = {}
  amount = 0
  entity = ""
  status = ""
  receipt = ""
  attempts = 0
  currency = ""
  offerId: string | null = null
  payments: any[] = []
  amountDue = 0
  createdAt = 0
  amountPaid = 0

  constructor(orderDetails: OrderDetailsResponse) {
    if (orderDetails) {
      this.id = orderDetails.id
      this.notes = new OrderNotes(orderDetails.notes)
      this.amount = orderDetails.amount
      this.entity = orderDetails.entity
      this.status = orderDetails.status
      this.receipt = orderDetails.receipt
      this.attempts = orderDetails.attempts
      this.currency = orderDetails.currency
      this.offerId = orderDetails.offer_id
      this.payments = orderDetails.payments
      this.amountDue = orderDetails.amount_due
      this.createdAt = orderDetails.created_at
      this.amountPaid = orderDetails.amount_paid
    }
  }
}

interface OrderResponse {
  user_id: string
  center_id: string
  order_status: string
  payment_gateway: string
  order_ref: string
  amount: number
  order_details: OrderDetailsResponse
  order_details_last_updated_at: string
  gst_detail_id: string
  is_gst_resolved: boolean
  gst_invoice_id: string
  id: string
  created_at: string
  created_timestamp: number
  updated_at: string
}

class Order {
  userId = ""
  centerId = ""
  orderStatus = ""
  paymentGateway = ""
  orderRef = ""
  amount = 0
  orderDetails = {}
  orderDetailsLastUpdatedAt = ""
  gstDetailId = ""
  isGstResolved = false
  gstInvoiceId = ""
  id = ""
  createdAt = ""
  createdTimestamp = 0
  updatedAt = ""

  constructor(order: OrderResponse) {
    if (order) {
      this.userId = order.user_id
      this.centerId = order.center_id
      this.orderStatus = order.order_status
      this.paymentGateway = order.payment_gateway
      this.orderRef = order.order_ref
      this.amount = order.amount
      this.orderDetails = new OrderDetails(order.order_details)
      this.orderDetailsLastUpdatedAt = order.order_details_last_updated_at
      this.gstDetailId = order.gst_detail_id
      this.isGstResolved = order.is_gst_resolved
      this.gstInvoiceId = order.gst_invoice_id
      this.id = order.id
      this.createdAt = order.created_at
      this.createdTimestamp = order.created_timestamp
      this.updatedAt = order.updated_at
    }
  }
}

interface CenterResponse {
  id: string
  name: string
  center_name_for_invoicing: string
  center_code: string
  is_active: boolean
  is_day_pass_enabled: boolean
}

class Center {
  id = ""
  name = ""
  centerNameForInvoicing = ""
  centerCode = ""
  isActive = false
  isDayPassEnabled = false

  constructor(center: CenterResponse) {
    if (center) {
      this.id = center.id
      this.name = center.name
      this.centerNameForInvoicing = center.center_name_for_invoicing
      this.centerCode = center.center_code
      this.isActive = center.is_active
      this.isDayPassEnabled = center.is_day_pass_enabled
    }
  }
}

export interface InvoiceOrderResponse {
  user_id: string
  gst_contact_id: string
  invoice_data: InvoiceResponse
  invoice_id: string
  invoice_number: string
  invoice_file_path: string
  invoice_url: string
  id: string
  created_at: string
  created_timestamp: number
  updated_at: string | null
  gst_contact: GstContactResponse
  order: OrderResponse
  center: CenterResponse
}

export default class InvoiceOrder {
  userId = ""
  gstContactId = ""
  invoiceData = {} as Invoice
  invoiceId = ""
  invoiceNumber = ""
  invoiceFilePath = ""
  invoiceUrl = ""
  id = ""
  createdAt = ""
  createdTimestamp = 0
  updatedAt: string | null = null
  gstContact = {} as GstContact
  order = {} as Order
  center = {} as Center

  constructor(invoiceOrder: InvoiceOrderResponse) {
    if (invoiceOrder) {
      this.userId = invoiceOrder.user_id
      this.gstContactId = invoiceOrder.gst_contact_id
      this.invoiceData = new Invoice(invoiceOrder.invoice_data)
      this.invoiceId = invoiceOrder.invoice_id
      this.invoiceNumber = invoiceOrder.invoice_number
      this.invoiceFilePath = invoiceOrder.invoice_file_path
      this.invoiceUrl = invoiceOrder.invoice_url
      this.id = invoiceOrder.id
      this.createdAt = invoiceOrder.created_at
      this.createdTimestamp = invoiceOrder.created_timestamp
      this.updatedAt = invoiceOrder.updated_at
      this.gstContact = new GstContact(invoiceOrder.gst_contact)
      this.order = new Order(invoiceOrder.order)
      this.center = new Center(invoiceOrder.center)
    }
  }
}
