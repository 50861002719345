import axiosConfig from "../app/axiosConfig"
import { ORDERS_INVOICES_API } from "../constants/apis"
import { fetchInvoicesPayloadType } from "../slices/invoicesSlice"

export const fetchInvoicesService = ({ payload }: fetchInvoicesPayloadType) => {
  // Construct the params object, including only non-empty values
  const params: Record<string, any> = {
    page: payload.page + 1,
    limit: payload.pageSize,
    start_date: payload.startDate,
    end_date: payload.endDate,
  }

  // Add optional parameters only if they are provided
  if (payload.customerName && payload.customerName !== "")
    params.customer_name = payload.customerName
  if (payload.email && payload.email !== "") params.email = payload.email
  if (payload.mobile && payload.mobile !== "") params.mobile = payload.mobile
  if (payload.isGstResolved && payload.isGstResolved !== "none")
    params.is_gst_resolved = payload.isGstResolved
  if (payload.businessEntity && payload.businessEntity !== "all")
    params.business_entity = payload.businessEntity
  if (payload.bookingId && payload.bookingId !== "")
    params.booking_id = payload.bookingId
  if (payload.bookingCode && payload.bookingCode !== "")
    params.booking_code = payload.bookingCode

  // Send the GET request with the filtered params
  return axiosConfig.get(ORDERS_INVOICES_API, { params })
}
