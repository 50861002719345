import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { FormikProps, useFormikContext } from "formik"
import { Transaction } from "../../models/transactions.model"
import {
  ReconciliationFormItem,
  ReconciliationFormValues,
} from "../../models/reconciliation.model"
import { RootState } from "../../app/store"
import { AnimatedButton, PendingInvoicesTitle, StyledBox } from "./styles"
import { InvoiceItem } from "./InvoiceItem"
import { Invoice } from "../../models/invoice.model"
import { Alert, TextField } from "@mui/material"
import { formatToTwoDecimalPlaces } from "../../utils/decimal-Formatter"

interface PendingInvoicesListProps {
  transactionData: Transaction
  formikProps: FormikProps<ReconciliationFormValues>
}

export const PendingInvoicesList: React.FC<PendingInvoicesListProps> = ({
  transactionData,
  formikProps,
}) => {
  const { setFieldValue, values, initialValues } = useFormikContext<any>()
  const [balanceAmountCalculationText, setBalanceAmountCalculationText] =
    useState<Record<string, string>>({})

  const { selectedCustomer, invoices, invoicesFetchError } = useSelector(
    (state: RootState) => state.reconciliation,
  )

  const isTransactionAmountConsumed = useMemo(() => {
    return formikProps.values.balanceAmount <= 0
  }, [formikProps.values.balanceAmount])

  useEffect(() => {
    if (values.addExcessPayment) {
      const balanceAmount =
        initialValues.balanceAmount - (parseFloat(values.excessAmount) || 0)
      setFieldValue("excessAmount", parseFloat(values.excessAmount) || 0)
      setFieldValue("balanceAmount", formatToTwoDecimalPlaces(balanceAmount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.addExcessPayment])

  const updateBalanceAmount = (
    reconciliationItems: ReconciliationFormItem[],
  ) => {
    const balance =
      transactionData.amount -
      reconciliationItems.reduce((acc, current) => {
        if (current.amount) {
          acc = acc + current.amount
        }
        return acc
      }, 0)
    formikProps.setFieldValue(
      "balanceAmount",
      formatToTwoDecimalPlaces(balance),
    )
    updateBalanceCalculationText(reconciliationItems)
  }

  const updateBalanceCalculationText = (
    reconciliationItems: ReconciliationFormItem[],
  ) => {
    let calculationText: Record<string, string> = {}

    reconciliationItems.forEach((item) => {
      const roundedTransactionAmount = formatToTwoDecimalPlaces(
        transactionData.amount,
      )
      const totalDeductions = reconciliationItems.reduce((acc, current) => {
        if (current.amount && current.invoiceId !== item.invoiceId) {
          return acc + formatToTwoDecimalPlaces(current.amount)
        }
        return acc
      }, 0)

      const balance = formatToTwoDecimalPlaces(
        roundedTransactionAmount - totalDeductions,
      )

      const text = `${roundedTransactionAmount} ${reconciliationItems.reduce(
        (acc, current) => {
          if (current.amount && current.invoiceId !== item.invoiceId) {
            acc = acc + ` - ${formatToTwoDecimalPlaces(current.amount)}`
          }
          return acc
        },
        ``,
      )} = ${balance}`

      if (balance > 0) {
        if (balance !== roundedTransactionAmount) {
          calculationText = {
            ...calculationText,
            [item.invoiceId]: text,
          }
        } else {
          calculationText = {
            ...calculationText,
            [item.invoiceId]: balance.toString(),
          }
        }
      }
    })
    setBalanceAmountCalculationText(calculationText)
  }

  const getTaxAmountWithheld = (
    invoice: Invoice,
    tdsPercentage: number,
  ): number => {
    const key = `tds${tdsPercentage}Percent` as keyof Invoice
    return invoice[key] ? Number(invoice[key]) - invoice.tdsAlreadyDeducted : 0
  }
  const getPossibleTds = useMemo(() => {
    const sortedPossibleTds = formikProps.values.customer?.possibleTds
      ? [...formikProps.values.customer.possibleTds].sort((a, b) => b - a)
      : []

    return (invoice: Invoice) => {
      if (
        invoice.canTdsDeducted([formikProps.values.customer?.defaultTds ?? 0])
      ) {
        return formikProps.values.customer?.defaultTds ?? 0
      }

      for (const tds of sortedPossibleTds) {
        if (invoice.canTdsDeducted([tds])) {
          return tds
        }
      }

      return 0
    }
  }, [
    formikProps.values.customer?.possibleTds,
    formikProps.values.customer?.defaultTds,
  ])

  const handleInvoiceToggle = (invoice: Invoice, checked: boolean) => {
    const currentItems = [...formikProps.values.reconciliationItems]
    if (checked) {
      const defaultTds = getPossibleTds(invoice) ?? 0
      const amount =
        invoice.balance -
        getTaxAmountWithheld(invoice, defaultTds) -
        invoice.tdsAlreadyDeducted

      const newItem: ReconciliationFormItem = {
        invoiceId: invoice.invoiceId,
        invoiceNumber: invoice.invoiceNumber,
        balanceAmount: invoice.balance,
        amount: formatToTwoDecimalPlaces(amount),
        isTdsDeducted: invoice.canTdsDeducted(
          formikProps.values.customer?.possibleTds ?? [],
        ),
        taxAmountWithheld: getTaxAmountWithheld(invoice, defaultTds),
        tdsPercentage: defaultTds,
        tdsAlreadyDeducted: invoice.tdsAlreadyDeducted,
      }
      currentItems.push(newItem)
    } else {
      const index = currentItems.findIndex(
        (item) => item.invoiceId === invoice.invoiceId,
      )
      if (index !== -1) {
        currentItems.splice(index, 1)
      }
    }
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const handleTaxEnabledToggle = (invoice: Invoice, isEnabled: boolean) => {
    const currentItems = formikProps.values.reconciliationItems.map((item) => {
      if (item.invoiceId === invoice.invoiceId) {
        item.isTdsDeducted = isEnabled
        item.taxAmountWithheld = getTaxAmountWithheld(
          invoice,
          isEnabled ? item.tdsPercentage : 0,
        )
        item.amount = formatToTwoDecimalPlaces(
          invoice.balance -
            getTaxAmountWithheld(invoice, isEnabled ? item.tdsPercentage : 0) -
            invoice.tdsAlreadyDeducted,
        )
      }
      return item
    })
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const handleTaxPercentToggle = (
    invoice: Invoice,
    taxPercent: number | null,
  ) => {
    const currentItems = formikProps.values.reconciliationItems.map((item) => {
      if (item.invoiceId === invoice.invoiceId && taxPercent) {
        item.tdsPercentage = taxPercent
        item.taxAmountWithheld = getTaxAmountWithheld(invoice, taxPercent)
        item.amount = formatToTwoDecimalPlaces(
          invoice.balance -
            getTaxAmountWithheld(invoice, taxPercent) -
            invoice.tdsAlreadyDeducted,
        )
      }
      return item
    })
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const handleAmountChange = (invoiceId: string, amount: number | "") => {
    const currentItems = formikProps.values.reconciliationItems.map((item) => {
      if (item.invoiceId === invoiceId) {
        item.amount = formatToTwoDecimalPlaces(amount)
      }
      return item
    })
    formikProps.setFieldValue("reconciliationItems", currentItems)
    updateBalanceAmount(currentItems)
  }

  const isInvoiceSelected = (invoiceId: string): boolean => {
    return formikProps.values.reconciliationItems.some(
      (item) => item.invoiceId === invoiceId,
    )
  }

  const getReconciliationItemIndex = (invoiceId: string): number => {
    return formikProps.values.reconciliationItems.findIndex(
      (item) => item.invoiceId === invoiceId,
    )
  }

  if (!selectedCustomer) {
    return null
  }

  const handleAddExcessClick = () => {
    setFieldValue("addExcessPayment", !values.addExcessPayment)
    setFieldValue("balanceAmount", initialValues.balanceAmount)
    setFieldValue("excessAmount", initialValues.excessAmount)
    setFieldValue("reconciliationItems", initialValues.reconciliationItems)
  }

  const handleExcessAmountChange = (amount: string) => {
    const value = amount.replace(/[^0-9]/g, "")
    const balanceAmount = initialValues.balanceAmount - (parseFloat(value) || 0)
    setFieldValue("excessAmount", parseFloat(value) || 0)
    setFieldValue("balanceAmount", formatToTwoDecimalPlaces(balanceAmount))
  }

  return (
    <>
      <StyledBox>
        <PendingInvoicesTitle>Pending Invoices</PendingInvoicesTitle>
        <AnimatedButton
          color="inherit"
          onClick={handleAddExcessClick}
          className={values.addExcessPayment ? "flipped" : ""}
        >
          <span className="flip-text back">Add pending invoice</span>
          <span className="flip-text front">Add excess payment</span>
        </AnimatedButton>
      </StyledBox>
      {Boolean(invoicesFetchError) && (
        <Alert severity="warning">{invoicesFetchError}</Alert>
      )}
      {!values.addExcessPayment ? (
        invoices.map((invoice) => {
          const isSelected = isInvoiceSelected(invoice.invoiceId)
          const itemIndex = getReconciliationItemIndex(invoice.invoiceId)
          const reconciliationItem = isSelected
            ? formikProps.values.reconciliationItems[itemIndex]
            : null
          return (
            <InvoiceItem
              key={invoice.invoiceId}
              txnInvoiceSource={transactionData.invoiceSource}
              formik={formikProps}
              isSelected={isSelected}
              invoice={invoice}
              reconciliationItem={reconciliationItem}
              handleInvoiceToggle={handleInvoiceToggle}
              itemIndex={itemIndex}
              handleTaxPercentToggle={handleTaxPercentToggle}
              handleAmountChange={handleAmountChange}
              handleTaxEnabledToggle={handleTaxEnabledToggle}
              isTransactionAmountConsumed={isTransactionAmountConsumed}
              balanceAmountCalculationText={balanceAmountCalculationText}
            />
          )
        })
      ) : (
        <TextField
          size="small"
          variant="outlined"
          label="Enter Excess Amount"
          placeholder="Enter Excess Amount"
          type="tel"
          value={values.excessAmount}
          onChange={(e) => {
            handleExcessAmountChange(e.target.value)
          }}
        />
      )}
    </>
  )
}
