import React, { useCallback, useEffect } from "react"
import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  clearReconciliation,
  submitReconciliationExcessPaymentRequest,
  submitReconciliationRequest,
} from "../../slices/reconcilationSlice"
import { AppDispatch, RootState } from "../../app/store"
import {
  CloseIconButton,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  StyledDrawer,
} from "../Transactions/styles"
import CloseIcon from "@mui/icons-material/Close"
import { Transaction } from "../../models/transactions.model"
import {
  ReconciliationFormValues,
  ReconciliationDTO,
  Reconciliation,
  ReconciliationExcessAmountDTO,
} from "../../models/reconciliation.model"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"
import {
  getProcessSchedule,
  getTransactionList,
  selectTransactions,
} from "../../slices/transactionsSlice"
import { constructApiRequestParams } from "../../utils/transactionHelpers"
import { useAppSelector } from "../../app/hooks"
import { selectLogin } from "../../slices/loginSlice"
import ReconciliationForm from "./ReconciliationForm"

type ReconciliationDrawerProps = {
  open: boolean
  onClose: () => void
  transactionData: Transaction
}

const initialValues = (
  transactionData: Transaction,
): ReconciliationFormValues => ({
  transactionId: transactionData.id,
  balanceAmount: transactionData.amount,
  customer: null,
  reconciliationItems: [],
  addExcessPayment: false,
  excessAmount: transactionData.amount,
})

const ReconciliationDrawer: React.FC<ReconciliationDrawerProps> = ({
  open,
  onClose,
  transactionData,
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const {
    submitReconciliationSuccess,
    submitReconciliationExcessPaymentSuccess,
  } = useSelector((state: RootState) => state.reconciliation)

  const handleSubmit = (values: ReconciliationFormValues) => {
    if (!values.customer) return
    if (values.addExcessPayment) {
      const submitData: ReconciliationExcessAmountDTO =
        Reconciliation.createReconciliationExcessPaymentDTO(values)
      dispatch(submitReconciliationExcessPaymentRequest(submitData))
    } else {
      const submitData: ReconciliationDTO =
        Reconciliation.createReconciliationDTO(values)
      dispatch(submitReconciliationRequest(submitData))
    }
  }

  const { filters, pagination } = useAppSelector(selectTransactions)

  const { role } = useAppSelector(selectLogin)

  const handleClose = useCallback(() => {
    dispatch(clearReconciliation())
    onClose()
  }, [dispatch, onClose])

  useEffect(() => {
    if (
      submitReconciliationSuccess ||
      submitReconciliationExcessPaymentSuccess
    ) {
      handleClose()
      dispatch(
        getTransactionList(constructApiRequestParams(filters, pagination)),
      )
      if (allowedOnlyBankAccountsRoles.includes(role)) {
        dispatch(getProcessSchedule())
      }
    }
  }, [
    dispatch,
    filters,
    handleClose,
    pagination,
    role,
    submitReconciliationSuccess,
    submitReconciliationExcessPaymentSuccess,
  ])

  return (
    <StyledDrawer open={open} onClose={handleClose} anchor="right">
      <DrawerHeader>
        <DrawerTitle>Reconcile Manually</DrawerTitle>
        <CloseIconButton onClick={handleClose}>
          <CloseIcon />
        </CloseIconButton>
      </DrawerHeader>
      <DrawerContent>
        <Formik
          initialValues={initialValues(transactionData)}
          validationSchema={Reconciliation.reconcilationFormValidationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <ReconciliationForm
              formikProps={formikProps}
              transactionData={transactionData}
            />
          )}
        </Formik>
      </DrawerContent>
    </StyledDrawer>
  )
}

export default ReconciliationDrawer
