import { takeLatest, put, call } from "redux-saga/effects"
import {
  fetchInvoices,
  fetchInvoicesFailed,
  fetchInvoicesPayloadType,
  fetchInvoicesSuccess,
} from "../slices/invoicesSlice"
import { AxiosResponse } from "axios"
import { fetchInvoicesService } from "../services/invoicesService"
import InvoiceOrder, {
  InvoiceOrderResponse,
} from "../models/InvoiceOrder.model."

export function* fetchInvoicesSaga(action: fetchInvoicesPayloadType) {
  try {
    const { data }: AxiosResponse = yield call(fetchInvoicesService, action)
    yield put(
      fetchInvoicesSuccess({
        invoices: data.data.map(
          (invoice: InvoiceOrderResponse) => new InvoiceOrder(invoice),
        ),
        totalRowCount: data.pagination.total || 0,
      }),
    )
  } catch (err) {
    yield put(fetchInvoicesFailed())
  }
}

export default function* invoicesSaga() {
  yield takeLatest(fetchInvoices, fetchInvoicesSaga)
}
