import {
  Box,
  BoxProps,
  Button,
  Divider,
  ToggleButtonGroup,
  Typography,
  TypographyProps,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Switch from "@mui/material/Switch"

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(255, 193, 0, 1)",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "rgba(255, 193, 0, 1)",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "rgba(255, 193, 0, 1)",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}))

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  height: "24px",
  "& button": {
    borderRadius: "22px",
  },
}))

export const CustomerLabel = styled(Box)`
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
`

// TransactionDetailsCard
export const TransactionDetailsCard = styled(Box)`
  border-radius: 6px;
  background: rgba(254, 249, 249, 1);
  border: 1px solid rgba(235, 239, 243, 1);
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0px;
`

export const StyledDivider = styled(Divider)(() => ({
  marginTop: "4px",
  marginBottom: "4px",
}))

export const DetailLabel = styled(Typography)`
  color: rgba(124, 130, 139, 1);
  font-weight: 500;
  font-size: 14px;
`

export const AmountContainer = styled(Box)`
  vertical-align: baseline;
`

export const BalanceAmount = styled(Typography)<
  TypographyProps & { error: boolean }
>(({ theme, error }) => ({
  color: error ? theme.palette.error.main : "rgba(52, 64, 84, 1)",
  fontWeight: 600,
  fontSize: "18px",
}))

export const TransactionAmount = styled(Typography)<TypographyProps>(() => ({
  color: "rgba(97, 106, 120, 1)",
  fontWeight: 500,
  fontSize: "12px",
}))

export const ErrorText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "12px",
}))

export const InputHelperText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.info.main,
    fontSize: "10px",
  }),
)

export const Description = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: rgba(52, 64, 84, 1);
  word-break: break-word;
`
//
export const PendingInvoicesTitle = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  padding: 6px;
  line-height: 20px;
`

// Invoice Item
export const InvoiceItemContainer = styled(Box)<
  BoxProps & { selected: boolean }
>(({ selected }) => ({
  borderRadius: "6px",
  border: selected
    ? "0.5px solid rgba(255, 193, 0, 1)"
    : "1px solid rgba(235, 239, 243, 1)",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  boxShadow: selected ? "0px 2px 5px 0px rgba(0, 0, 0, 0.11)" : "none",
}))

export const InvoiceItemHeader = styled(Box)<BoxProps & { selected: boolean }>(
  ({ selected }) => ({
    background: selected ? "rgba(254, 249, 249, 1)" : "transparent",
    borderBottom: selected ? "1px solid rgba(235, 239, 243, 1)" : "none",
    padding: "4px 12px",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    height: "50px",
  }),
)

export const InvoiceItemHeaderContent = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
})

export const InvoiceItemHeaderText = styled(Typography)<TypographyProps>({
  color: "rgba(71, 84, 103, 1)",
  fontSize: "12px",
  fontWeight: "400",
})

export const InvoiceItemHeaderContentText = styled(Typography)<TypographyProps>(
  {
    fontSize: "12px",

    fontWeight: "600",
  },
)

export const InvoiceItemTaxDetails = styled(Box)({
  padding: "8px 12px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  paddingBottom: "16px",
})

export const TdsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  height: "24px",
})

export const TdsLabel = styled(Typography)<TypographyProps>({
  color: "rgba(52, 64, 84, 1)",
  fontSize: "12px",
  fontWeight: "500",
})

export const TdsAmount = styled(Typography)<TypographyProps>({
  flex: 1,
  textAlign: "end",
  color: "rgba(52, 64, 84, 1)",
  fontSize: "12px",
  fontWeight: "500",
})

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;

  & .MuiButton-root {
    text-transform: none;
    text-decoration: underline;
  }
  & .MuiButton-startIcon {
    margin-right: 0;
  }
`

export const AnimatedButton = styled(Button)`
  position: relative;
  min-width: 140px;

  .flip-text {
    text-decoration: underline;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    transition: transform 0.5s ease-in-out;
  }

  .front {
    transform: rotateX(0deg);
  }

  .back {
    transform: rotateX(180deg);
  }

  &.flipped .front {
    transform: rotateX(-180deg);
  }

  &.flipped .back {
    transform: rotateX(0deg);
  }
`
