import dayjs from "dayjs"
import { useAppSelector } from "../../app/hooks"
import Table from "../../components/Table/Table"
import { selectInvoices } from "../../slices/invoicesSlice"
import { GridColDef, GridRowsProp } from "@mui/x-data-grid"
import { formatPriceWithRupeeSymbol } from "../../utils/price-formatter"

interface InvoiceTableProps {
  rowCount: number
  paginationModel: any
  onPaginationModelChange: (model: any) => void
  isSmallScreen: boolean
}

const InvoiceTable = ({
  rowCount,
  paginationModel,
  onPaginationModelChange,
}: InvoiceTableProps) => {
  const { invoices, loading } = useAppSelector(selectInvoices)

  const columns: GridColDef[] = [
    { field: "companyName", headerName: "Company Name", width: 300 },
    { field: "invoiceNumber", headerName: "Invoice Number", width: 150 },
    { field: "centerName", headerName: "Center Name", width: 300 },
    { field: "invoiceDate", headerName: "Invoice Date", width: 150 },
    { field: "invoiceDueDate", headerName: "Invoice Due Date", width: 150 },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "gstNumber", headerName: "GST Number", width: 150 },
  ]

  const rows: GridRowsProp = invoices.map((invoice) => ({
    id: invoice?.invoiceId,
    invoiceNumber: invoice?.invoiceNumber,
    companyName: invoice?.gstContact?.contactInfo?.companyName,
    centerName: invoice?.center?.name,
    invoiceDate: dayjs(invoice?.invoiceData?.date).format("DD/MM/YYYY"),
    invoiceDueDate: dayjs(invoice?.invoiceData?.dueDate).format("DD/MM/YYYY"),
    amount: formatPriceWithRupeeSymbol(
      invoice.invoiceData.lineItems.reduce(
        (sum, item) => sum + item.itemTotal,
        0,
      ),
    ),
    gstNumber: invoice.invoiceData.gstNo,
  }))

  return (
    <Table
      rows={rows}
      columns={columns}
      loading={loading}
      rowCount={rowCount}
      pageSizeOptions={[10, 25, 50]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={onPaginationModelChange}
    />
  )
}

export default InvoiceTable
